//
// CAMPTON
//

@font-face {
  font-family: 'camptonblack_italic';
  src: url('./campton/campton_black_italic-webfont.woff2') format('woff2'),
       url('./campton/campton_black_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'camptonblack';
  src: url('./campton/campton_black-webfont.woff2') format('woff2'),
       url('./campton/campton_black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'camptonbold_italic';
  src: url('./campton/campton_bold_italic-webfont.woff2') format('woff2'),
       url('./campton/campton_bold_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'camptonbold';
  src: url('./campton/campton_bold-webfont.woff2') format('woff2'),
       url('./campton/campton_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'camptonbook_italic';
  src: url('./campton/campton_book_italic-webfont.woff2') format('woff2'),
       url('./campton/campton_book_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'camptonbook';
  src: url('./campton/campton_book-webfont.woff2') format('woff2'),
       url('./campton/campton_book-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'camptonextrabold_italic';
  src: url('./campton/campton_extrabold_italic-webfont.woff2') format('woff2'),
       url('./campton/campton_extrabold_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'camptonextrabold';
  src: url('./campton/campton_extrabold-webfont.woff2') format('woff2'),
       url('./campton/campton_extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'camptonextralight_italic';
  src: url('./campton/campton_extralight_italic-webfont.woff2') format('woff2'),
       url('./campton/campton_extralight_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'camptonextralight';
  src: url('./campton/campton_extralight-webfont.woff2') format('woff2'),
       url('./campton/campton_extralight-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'camptonlight_italic';
  src: url('./campton/campton_light_italic-webfont.woff2') format('woff2'),
       url('./campton/campton_light_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'camptonlight';
  src: url('./campton/campton_light-webfont.woff2') format('woff2'),
       url('./campton/campton_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
