.custom-checkbox.mat-checkbox {
  font-family: inherit;

  &.mat-checkbox-checked .mat-checkbox-checkmark {
    margin: auto;
    width: 75%;
    height: 75%;
  }
}

.custom-checkbox.mat-checkbox {
  &,
  .mat-checkbox-layout,
  .mat-checkbox-layout .mat-checkbox-label {
    width: 100%;
  }
}

.custom-checkbox .mat-checkbox-inner-container {
  width: 24px;
  height: 24px;

  .mat-checkbox-frame,
  .mat-checkbox-background {
    border-radius: 6px;
  }
}


.cart-checklist {
  &__item {
    padding: 32px 0;
  }

  &__item img {
    object-fit: cover;
    min-width: 100px;
    min-height: 100px;
  }

  &__item .info h4 {
    font-size: 20px;
  }

  &__item .price {
    font-size: 16px;
  }
}

.cart-checklist--disabled {

  .cart-checklist__item {

    > div:first-child {
      filter: opacity(0.25);
    }

    &:hover {
      filter: grayscale(0);
    }
  }
}



