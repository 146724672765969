//
// Reboot
//

h1, h2, h3, h4, h5, h6 {
	color: var(--kt-headings-color);
	outline: 0;

  &.fw-bolder {
    font-family: 'camptonbold'
  }

  &.fw-normal,
  & > .fw-normal {
    font-family: 'camptonbook';
  }

}
