//
// Animation
//

// Base
.animation {
  animation-duration: 1s;
  animation-fill-mode: both;
}

// Slide In Down
@keyframes animationSlideInDown {
    from {
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

.animation-slide-in-down {
    animation-name: animationSlideInDown;
}

// Slide In Up
@keyframes animationSlideInUp {
    from {
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

.animation-slide-in-up {
    animation-name: animationSlideInUp;
}

// Fade In
@keyframes animationFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.animation-fade-in {
    animation-name: animationFadeIn;
}

// Fade Out
@keyframes animationFadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
.animation-fade-out {
    animation-name: animationFadeOut;
}

// Blink
.animation-blink {
    animation: animationBlink 1s steps(5, start) infinite;
}

@keyframes animationBlink {
    to {
        visibility: hidden;
    }
}

@keyframes animationWobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animation-wobble {
  animation: animationWobble 1s steps(5, start) infinite;
}
