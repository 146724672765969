
@use '@angular/material' as mat;
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`

@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$mai-olive-palette: (
  50: #f3f6e9,
  100: #e2e9c9,
  200: #cedba7,
  300: #bbcd84,
  400: #adc36b,
  500: #9fb952,
  600: #8fa94a,
  700: #7b9640,
  800: #688238,
  900: #486029,
  contrast: (
    50: rgba(black, 0.85),
    100: rgba(black, 0.85),
    200: rgba(black, 0.85),
    300: rgba(black, 0.85),
    400: rgba(black, 0.85),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

//primary
$mai-primary: mat.define-palette($mai-olive-palette, 800);
//accent
$mai-accent: mat.define-palette($mai-olive-palette, 100, 200, 300);
//warn

$mai-light-theme: mat.define-light-theme((
  color: (
    primary: $mai-primary,
    accent: $mai-accent
  )
));

@include mat.all-legacy-component-themes($mai-light-theme);

// override mat styles
.custom-overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.4);

  @media (min-width: 992px) {
    top: var(--kt-app-header-height);
  }
}

// .mat-button
.mat-button,
.mat-button-base,
.mat-tooltip{
  line-height: 1;
  font-family: "Inter", sans-serif;
}

//.mat-tab-group
.mat-tab-group,
.mat-tab-label {
  font-family: "Inter", sans-serif;
}

.custom-tab-group {

  &.mat-primary .mat-ink-bar {
    background-color: var(--kt-primary);
  }

  .mat-tab-label {
    height: 68px;
  }

  .mat-tab-label:hover,
  .mat-tab-label-active {
    color: var(--kt-primary);
  }

  .mat-tab-label-active .mat-tab-label-content {
    font-weight: 700;
  }
}

// mat tooltip
.custom-tooltip-panel {
  padding: 8px 16px;
  margin: 7px!important;
  background: var(--kt-primary);
  color: var(--kt-white);
  border-radius: 4px;
  max-width: max-content!important;

  letter-spacing: 0.01em;
  font: {
    size: 12px;
  }
}

