//
// SVG Icon
//

.svg-icon {
    line-height: 1;
    color: var(--kt-text-muted);
    @include svg-icon-size(get($font-sizes, 5));

    // Theme colors
    @each $name, $color in $theme-text-colors {
        &.svg-icon-#{$name} {
            color: var(--kt-text-#{$name});
        }
    }

    // Responsive icon sizes
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            // Sizes
            @each $name, $value in $font-sizes {
                &.svg-icon#{$infix}-#{$name} {
                    @include svg-icon-size($value, true);
                }
            }
        }
    }

  &-solid svg {
    fill: var(--kt-gray-700);
  }

  &-stroke svg {
    stroke: var(--kt-gray-700);
  }
}

.fill {

  &-primary svg {
    fill: var(--kt-primary);
  }

  &-primary-light svg {
    fill: #A5CE46;
  }

  &-info svg {
    fill: var(--kt-info);
  }

  &-white svg {
    fill: var(--kt-white);
  }

  &-gray-700 {
    fill: var(--kt-gray-700)
  }
}

.stroke {
  &-primary svg {
    stroke: var(--kt-primary);
  }

  &-primary-light svg {
    stroke: #A5CE46;
  }

  &-white svg {
    stroke: var(--kt-white);
  }

  &-gray-900 svg {
    stroke: var(--kt-gray-900);
  }
}
