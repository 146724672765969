//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: #ffffff !default;
$black:#000000 !default;

// Theme colors
// Primary
$primary:       									#688238;
$primary-active:    								lighten(#688238, 5%);
$primary-light:    								    #f8f8f8;
$primary-inverse:  									$white;
$primary-light-green: #F6FAEC !default;

// Success
$success:       									#50cd89;
$success-active:    								#47be7d;
$success-light:    									#e8fff3;
$success-inverse:  									$white;

// Info
$info:       									    #5EC8F2;
$info-active:    									#5014d0;
$info-light:    									#E3FEFF;
$info-inverse:  									$white;

// Danger
$danger:       									    #CC4435;
$danger-active:    									#d9214e;
$danger-light:    									#fff5f8;
$danger-inverse:  									$white;

// Warning
$warning:       									#CCC118;
$warning-active:    								#F29F05;
$warning-light:    									#FFFCF2;
$warning-inverse:  									$white;

// Grey Colors
$gray-000: #f0f0f0 !default;
$gray-100: #f8f8f8 !default;
$gray-200: #eff2f5 !default;
$gray-300: #d1d1d1 !default;
$gray-400: #B5B5C3 !default;
$gray-500: #A1A5B7 !default;
$gray-600: #7E8299 !default;
$gray-700: #808080 !default;
$gray-800: #3F4254 !default;
$gray-900: #353535 !default;

// Bootstrap muted color
$text-muted: $gray-500 !default;

// Body
//
// Settings for the `<body>` element.
$body-bg:                   $white !default;
$body-bg-rgb:               to-rgb($body-bg) !default;
$body-color:                $gray-900 !default;
$body-text-align:           null !default;

// Links
//
// Style anchor elements.
$link-color:                $primary !default;
$link-color-dark:           $primary !default;
$link-decoration:           none !default;
$link-shade-percentage:     20% !default;
$link-hover-color:          $primary-active !default;
$link-hover-color-dark:     $primary-active !default;
$link-hover-decoration:     none !default;


// Components
//
// Define common padding and border radius sizes and more.
$border-width:                  1px !default;
$border-color:                  $gray-200 !default;
$border-dashed-color:           $gray-300 !default;

$border-widths: (
	0: 0,
	1: 1px,
	2: 2px,
	3: 3px,
	4: 4px,
	5: 5px
) !default;

// Border Radiues
$border-radius:                 .475rem !default;
$border-radius-sm:              .425rem !default;
$border-radius-lg:              .625rem !default;
$border-radius-xl:              1rem !default;
$border-radius-2xl:             2rem !default;
$border-radius-pill:            50rem !default;

// Keenthemes hover states
$component-hover-color: 		$primary !default;
$component-hover-bg: 			#F4F6FA !default;

// Keenthemes active states
$component-active-color:        $primary-inverse !default;
$component-active-bg:           $primary !default;

// Keenthemes checked states
$component-checked-color:        $primary-inverse !default;
$component-checked-bg:           $primary !default;

// Keenthemes custom transition settings
$transition-link: color .2s ease !default;
$transition-input: color .2s ease !default;

// Box shadow
$box-shadow-xs: 0 .1rem 0.75rem 0.25rem rgba($black, 0.05) !default;
$box-shadow-sm: 0 .1rem 1rem 0.25rem rgba($black, .05) !default;
$box-shadow: 0 .5rem 1.5rem 0.5rem rgba($black, .075) !default;
$box-shadow-lg: 0 1rem 2rem 1rem rgba($black, .1) !default;
$box-shadow-inset: inset 0 1px 2px rgba($black, .075) !default;

// Card
$card-border-color: $gray-300 !default;


// Typography
//
// Font, line-height, and color for body text, headings, and more.
// Font family
$font-family-inter: "Inter" !default;

$font-family-campton: 'camptonbook' !default;
$font-campton-bold: 'camptonbold' !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `13px`
$font-size-lg: $font-size-base * 1.075 !default;   // 14.04px
$font-size-sm: $font-size-base * .925 !default;   // 12.025px

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semibold: 500 !default;
$font-weight-bold: 600 !default;
$font-weight-bolder: 700 !default;

$font-weight-base: $font-weight-normal !default;

// Line heights
$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 1.75 !default;

// Keenthemes custom line heights
$line-height-xl: 2 !default;
$line-height-xxl: 2.25 !default;

// Heading sizes
$h1-font-size:  2.2857rem !default; // 32px
$h2-font-size:  2rem !default; // 28px
$h3-font-size:  1.7143rem !default; // 24px
$h4-font-size:  1.4286rem !default; // 20px
$h5-font-size:  $font-size-base * 1.15 !default; // 14.95px
$h6-font-size:  $font-size-base * 1.075 !default; // 13.97px

// Font Sizes
$font-sizes: (
	1: $h1-font-size,   // 32px
	2: $h2-font-size,   // 28px
	3: $h3-font-size,   // 24px
	4: $h4-font-size,   // 20px
	5: $h5-font-size,   // 14.95px
	6: $h6-font-size,   // 13.95px

	7: $font-size-base * 0.95,  // 12.35px
	8: $font-size-base * 0.85,  // 11.05px
	9: $font-size-base * 0.75,  // 9.75px
	10: $font-size-base * 0.5,  // 6.50px

	base: $font-size-base, // 13px
	fluid: 100%, // 100%

	2x: $font-size-base * 2,  // 26px
	2qx: $font-size-base * 2.25, // 29.25px
	2hx: $font-size-base * 2.5,  // 32.5px
	2tx: $font-size-base * 2.75, // 35.75px

	3x: $font-size-base * 3,  // 39px
	3qx: $font-size-base * 3.25, // 42.25px
	3hx: $font-size-base * 3.5,  // 45.5px
	3tx: $font-size-base * 3.75, // 48.75px

	4x: $font-size-base * 4,  // 52px
	4qx: $font-size-base * 4.25, // 55.25px
	4hx: $font-size-base * 4.5,  // 58.5px
	4tx: $font-size-base * 4.75, // 61.75px

	5x: $font-size-base * 5,  // 65px
	5qx: $font-size-base * 5.25, // 68.25px
	5hx: $font-size-base * 5.5,  // 71.5px
	5tx: $font-size-base * 5.75  // 74.75px
) !default;

$headings-font-weight: 			600 !default;
$headings-color: 				$gray-900 !default;
$headings-line-height:        	1.2 !default;

$display-font-sizes: (
 1: 5rem,
 2: 4.5rem,
 3: 4rem,
 4: 3.5rem,
 5: 3rem,
 6: 2.5rem
) !default;

$display-font-weight:  $font-weight-bolder !default;
$display-line-height:  $headings-line-height !default;

$blockquote-footer-color:     $gray-600 !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding-y:                      .75rem !default;
$table-cell-padding-x:                      .75rem !default;
$table-cell-padding-y-sm:                   .5rem !default;
$table-cell-padding-x-sm:                   .5rem !default;

$table-color:                               $body-color !default;
$table-bg:                                  transparent !default;
$table-accent-bg:                           transparent !default;
$table-striped-color:                       $table-color !default;
$table-striped-bg:                          rgba($gray-100, 0.75) !default;

$table-active-color:                        $table-color !default;
$table-active-bg:                           $gray-100 !default;

$table-hover-color:                         $table-color !default;
$table-hover-bg:                            $gray-100 !default;

$table-border-factor:                       .1 !default;
$table-border-width:                        $border-width !default;
$table-border-color:                        $border-color !default;

$table-caption-color:                       $text-muted !default;

$table-loading-message-box-shadow:          0px 0px 50px 0px rgba(82,63,105, 0.15) !default;
$table-loading-message-bg:                  $body-bg !default;
$table-loading-message-color:               $gray-700 !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y:                       .775rem !default;
$input-btn-padding-x:                       1rem !default;
$input-btn-font-family:                     null !default;
$input-btn-font-size:                       $font-size-base * 1.1 !default;
$input-btn-line-height:                     $line-height-base !default;

$input-btn-focus-width:                     .25rem !default;
$input-btn-focus-color-opacity:             .25 !default;
$input-btn-focus-color:                     rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-box-shadow:                null !default;

$input-btn-padding-y-sm:                    .55rem !default;
$input-btn-padding-x-sm:                    .75rem !default;
$input-btn-font-size-sm:                    $font-size-sm !default;

$input-btn-padding-y-lg:                    .825rem !default;
$input-btn-padding-x-lg:                    1.5rem !default;
$input-btn-font-size-lg:                    $font-size-base * 1.15 !default;

$input-btn-border-width:                    $border-width !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-padding-y:                             $input-btn-padding-y !default;
$btn-padding-x:                             1.5rem !default;
$btn-font-family:                           $input-btn-font-family !default;
$btn-font-size:                             $input-btn-font-size !default;
$btn-line-height:                           $input-btn-line-height !default;
$btn-white-space:                           null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:                          $input-btn-padding-y-sm !default;
$btn-padding-x-sm:                          1.25rem !default;
$btn-font-size-sm:                          $input-btn-font-size-sm !default;

$btn-padding-y-lg:                          $input-btn-padding-y-lg !default;
$btn-padding-x-lg:                          1.75rem !default;
$btn-font-size-lg:                          $input-btn-font-size-lg !default;

$btn-border-width:                          $input-btn-border-width !default;

$btn-font-weight:                           $font-weight-semibold !default;
$btn-box-shadow:                            null !default;
$btn-focus-width:                           $input-btn-focus-width !default;
$btn-focus-box-shadow:                      null !default;
$btn-disabled-opacity:                      .65 !default;
$btn-active-box-shadow:                     null !default;

$btn-link-color:                            $link-color !default;
$btn-link-hover-color:                      $link-hover-color !default;
$btn-link-disabled-color:                   $gray-600 !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:                         $border-radius !default;
$btn-border-radius-sm:                      $border-radius-sm !default;
$btn-border-radius-lg:                      $border-radius-lg !default;


// Forms
$form-text-margin-top:                      .5rem !default;
$form-text-font-size:                       $font-size-sm !default;
$form-text-color:                           $text-muted !default;

$form-label-margin-bottom:                  .5rem !default;
$form-label-font-size:                      1.05rem !default;
$form-label-font-weight:                    500 !default;
$form-label-color:                          $gray-800 !default;

$input-padding-y:                           $input-btn-padding-y !default;
$input-padding-x:                           $input-btn-padding-x !default;
$input-font-family:                         $input-btn-font-family !default;
$input-font-size:                           $input-btn-font-size !default;
$input-font-weight:                         $font-weight-semibold !default;
$input-line-height:                         $input-btn-line-height !default;

$input-bg:                                  $body-bg !default;

$input-border-width:                    	$input-btn-border-width !default;
$input-color:                               $gray-700 !default;
$input-border-color:                        $gray-300 !default;

$input-border-radius:                       $btn-border-radius !default;
$input-border-radius-sm:                    $btn-border-radius-sm !default;
$input-border-radius-lg:                    $btn-border-radius-lg !default;

$input-focus-bg:                            $input-bg !default;
$input-focus-border-color:                  $gray-400 !default;
$input-btn-focus-width:         			.25rem !default;
$input-focus-color:                         $input-color !default;
$input-focus-width:                     	$input-btn-focus-width !default;

$input-disabled-color:                      null !default;
$input-disabled-bg:                         $gray-200 !default;
$input-disabled-border-color:               $input-border-color !default;

$input-placeholder-color:                   $gray-300 !default;
$input-plaintext-color:                     $gray-700 !default;

// Keenthemes solid input style
$input-solid-bg:                            $gray-100 !default;
$input-solid-bg-focus:                      darken($gray-100, 2%) !default;
$input-solid-placeholder-color:             $input-placeholder-color !default;
$input-solid-color:         	            $gray-700 !default;

// Input groups
$input-group-addon-bg:                              $white !default;
$input-group-addon-color:                           $gray-700 !default;
$input-group-addon-border-color:                    $input-border-color !default;

// Alert
$alert-secondary-color: $gray-900;
