//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base/layout";
@import "layout/layout";

html {
  scroll-behavior: smooth;
}

.cdk-overlay-container {
  z-index: 3333333333; // above zoho bubble
}
