/* You can add global styles to this file, and also import other style files */

@import "swiper/swiper-bundle.min.css";

@import "./assets/sass/core/material-theme";

@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
@import './assets/sass/plugins.scss';
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";

/* Importing Bootstrap SCSS file. */
//@import 'bootstrap/scss/bootstrap';
